.qus-title {
    margin-top: get-vw-desktop(60px);

    @media only screen and (max-width: 770px) {
        margin-top: get-vw-mobile(30px);
    }

    p {
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: get-vw-desktop(48px);
        font-weight: 700;

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(18px);
        }

        span {
            color: #8CC8FF;
        }
    }
}

.qus-sub-title {
    margin-top: get-vw-desktop(30px);

    @media only screen and (max-width: 770px) {
        max-width: 90%;
        margin: auto;
        margin-bottom: 20px;
    }

    p {
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: get-vw-desktop(23px);

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(15px);
        }
    }
}

.cards {
    margin-top: get-vw-desktop(40px);
    margin-bottom:get-vw-desktop(30px) ;
    @media only screen and (max-width: 770px) {
        padding: 0 get-vw-mobile(10px);
        margin-bottom:0 ;
    }
}

.item {
    background-color: #fff;
    padding: get-vw-desktop(50px) get-vw-desktop(20px);
    padding-top: get-vw-desktop(20px);
    padding-bottom: get-vw-desktop(50px);
    border-radius: 10px;
    // overflow: hidden;
    margin: auto;
    position: relative;

    @media only screen and (max-width: 770px) {

        border-radius: 6px;
        //padding-bottom: get-vw-mobile(60px);
    }

    &::after {
        display: block;
        content: "";
        background-color: #868EA2;
        padding: get-vw-desktop(20px) 0;
        position: absolute;
        bottom: get-vw-desktop(-10px);
        width: 95%;
        right: 0;
        left: 0;
        margin: auto;
        border-radius: 10px;
        z-index: -1;

        @media only screen and (max-width: 770px) {
            padding: get-vw-mobile(20px) 0;
            bottom: get-vw-mobile(-10px);
        }
    }

    &::before {
        display: block;
        content: "";
        background-color: #495574;
        padding: get-vw-desktop(3px) 0;
        position: absolute;
        bottom: get-vw-desktop(-16px);
        width: 90%;
        right: 0;
        left: 0;
        margin: auto;
        border-radius: 0;
        z-index: 1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @media only screen and (max-width: 770px) {
            padding: get-vw-mobile(3px) 0;
            bottom: get-vw-mobile(-16px);
            width: 80%;
        }
    }

    .count-cards {
        margin-top: get-vw-desktop(60px);
        text-align: center;
        color: #0D1D46;
        margin-bottom: get-vw-desktop(5px);
        font-size: get-vw-desktop(31px);

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(16px);
            margin-top: get-vw-mobile(45px);
        }
    }

    .qus {
        text-align: center;
        font-weight: 700;
        color: #0D1D46;
        margin: auto;
        min-height: get-vw-desktop(100px);
        font-size: get-vw-desktop(31px);
        max-width: 90%;

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(18px);
            min-height: get-vw-mobile(80px);
        }
    }

    .answers {
        margin-top: get-vw-desktop(50px);
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 770px) {
            margin-top: get-vw-mobile(35px);
        }

        .wrap-ans {
            width: get-vw-desktop(100px);
            // height: get-vw-desktop(85px);
           // margin: 0 get-vw-desktop(14px);

            @media only screen and (max-width: 770px) {
                padding: 0;
                width: get-vw-mobile(55px);
                // height: get-vw-mobile(55px);
            }
        }

        .answer {
            text-align: center;
            border: 1px solid #2A64EC;
            border-radius: 7px;
            margin: auto;
           // padding: get-vw-desktop(20px) get-vw-desktop(30px);
            color: #2A64EC;
            font-weight: 700;
            cursor: pointer;
            opacity: 1;
            transition: all .25s ease;
            width: get-vw-desktop(75px);
            height: get-vw-desktop(75px);
            font-size:  get-vw-desktop(23px);
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                @media only screen and (min-width: 770px) {

                    background-color: #E8EAFD;
                }

            }

            @media only screen and (max-width: 770px) {

                width: get-vw-mobile(45px);
                height: get-vw-mobile(45px);
                font-size:  get-vw-mobile(18px);
                //padding: get-vw-mobile(10px) get-vw-mobile(20px);
            }



        }

        .rate-label {
            text-align: center;
            margin-top: 5px;
            color: #2A64EC;
            font-weight: 500;
            position: relative;
            z-index: 999;

            @media only screen and (max-width: 770px) {
                font-size: get-vw-mobile(14px);
                margin-bottom: 10px;
            }
        }
    }
}

.container-small {
    max-width: 712px;
}