@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;800&display=swap');
body{
    background-color: #0D1D46;
    margin: 0;
    background-image: url(../assets/images/bg.png);
    background-repeat: no-repeat;
    background-position: -615px;
    direction: rtl;
    text-align: right;

    @media only screen and (max-width: 770px) {
        background-position: -195px -79px;
        background-size: cover;
     }
}

body,p,a,div,input{
    font-family: 'afek';
}

.container{
    max-width: 700px;
    margin: auto;
}

.wrap-btn{
    text-align: center;
}
.btn{
    color: #fff;
    background-color: #2A64EC ;
    border-radius: get-vw-desktop(10px);
    display: inline-block;
    margin: auto;
    width: 100%;
    cursor: pointer;
    text-align: center;
    max-width:get-vw-desktop(380px) ;
    padding:  get-vw-desktop(10px)  get-vw-desktop(0px);
    margin-bottom:get-vw-desktop(10px) ;
    font-weight: 700;
    font-size: get-vw-desktop(26px);
    transition: all .25s ease;
    &:hover{
        background-color: #8AABFF;
    }

    @media only screen and (max-width: 770px) {
       max-width: 90%;
       padding:  get-vw-mobile(10px)  get-vw-mobile(0px);
       font-size: get-vw-mobile(26px);
    }
  
}

.App{
   
   /* display: flex;
    flex-direction: column;*/
}

.bg-filters{
    position: absolute;
    top:0;
    display: none;
    right:0;
    display: none;
    z-index: -1;
    img{
        max-width: 100%;
    }
}

.english-text{
    font-family: 'Open Sans', sans-serif !important;

    &.bold-en{
        font-weight: 600;
    }
}