.header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #28499D;
    align-items:center ;
    padding: get-vw-desktop(20px) get-vw-desktop(20px);
    background-color: #0D1D46 ;
   
    @media only screen and (max-width: 770px) {
        padding: get-vw-mobile(20px) get-vw-mobile(20px);
    }
}

.test{
    font-family: "Afek";
}