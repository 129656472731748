.big-title {
    line-height: 1;

    br {
        display: none;

        @media only screen and (max-width: 770px) {
            display: block;
        }
    }

    p {
        color: #fff;
        text-align: center;
        font-weight: 700;
        font-size: get-vw-desktop(84px);
        margin: 0;

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(46px);
        }
    }

    &.blue {
        p {
            color: #8CC8FF;
            margin: 0;
        }

    }
}

.wrap-disclamer-and-btn {
    //margin-bottom: get-vw-mobile(20px);

    .wrap-btn {
        margin-top: get-vw-desktop(55px);
    }
}

.wrap-home {
    flex: 1;

    @media only screen and (max-width: 770px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 93vh;
        max-height: -webkit-fill-available;
    }

    &.ios-device {
        @media only screen and (max-width: 770px) {
            min-height: 0;
            height: 100vh;
            max-height: -webkit-fill-available;
        }
    }
}

.content {

    @media only screen and (max-width: 770px) {
        padding: 0 get-vw-mobile(50px);
    }

    p {
        color: #fff;
        text-align: center;
        font-size: get-vw-desktop(31px);
        margin: get-vw-desktop(15px) 0;

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(21px);
        }
    }
}

.disclamer {
    @media only screen and (max-width: 770px) {
        margin-top: get-vw-mobile(14px);
    }

    p {
        color: #fff;
        text-align: center;
        font-size: get-vw-desktop(23px);
        margin: get-vw-desktop(10px) 0;

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(14px);
        }
    }
}

.image-status {
    margin-top: get-vw-desktop(100px);
    text-align: center;

    @media only screen and (max-width: 770px) {
        margin-top: get-vw-mobile(30px);
        margin-bottom: get-vw-mobile(20px);
    }

    img {
        @media only screen and (max-width: 770px) {
            max-width: get-vw-mobile(125px);
        }
    }
}

.results {
    margin-top: get-vw-desktop(20px);

    .result-item {
        margin-bottom: get-vw-desktop(20px);
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 get-vw-desktop(20px);
        border-radius: 7px;
        padding-bottom: 0;

        @media only screen and (max-width: 770px) {
            padding: 0 get-vw-mobile(20px);
            margin-bottom: get-vw-mobile(10px);
            border-radius: 5px;
        }

        &.minimal {
            background-color: #FFD764;
        }

        &.medium {
            background: #FFBE52;
        }

        &.high {
            background: #FF5151;

        }

        &.easy {
            background: #FFB843;
        }

        &.no-effect {
            background: #94F784;
        }

        .result-item-image {
            margin-top: get-vw-desktop(10px);
            padding-top: get-vw-desktop(15px);

            @media only screen and (max-width: 770px) {
                padding-top: get-vw-mobile(15px);
            }

            img {
                display: block;
                height: get-vw-desktop(130px);

                @media only screen and (max-width: 770px) {
                    height: get-vw-mobile(90px);
                }
            }
        }

        .result-item-label {
            color: #0D1D46;
            font-weight: 700;
            font-size: get-vw-desktop(32px);

            @media only screen and (max-width: 770px) {
                font-size: get-vw-mobile(21px);
            }
        }
    }
}

.wrap-btn-results {
    margin-top: get-vw-desktop(2px);
}

.wrap-results {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 95vh;

    .wrap-btn-results {
        margin-bottom: get-vw-mobile(0px);

        ;
    }
}

.container-small-pad {
    padding: 0 get-vw-mobile(20px);
    max-width: get-vw-desktop(570px) !important;

    @media only screen and (max-width: 770px) {
        max-width: get-vw-mobile(590px) !important;
    }
}

.qus-title-results {
    @media only screen and (max-width: 770px) {
        margin-bottom: get-vw-mobile(15px);
    }

    p {
        @media only screen and (max-width: 770px) {
            text-align: right !important;
            font-weight: 500 !important;
        }
    }
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba($color: #fff, $alpha: .7);
    display: flex;
    align-items: center;
    justify-content: center;
}

a.dino-link {
    display: block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    &.dino-link-home {}

    img {
        max-width: 100px;
    }
}

.qus-sub-title-res {
    margin-top: get-vw-desktop(10px) !important;
}

.content-m {
    @media only screen and (max-width: 770px) {
        margin-top: get-vw-mobile(15px);
    }
}

.med12,
.logo-quiz {
    cursor: pointer;
}

.fade-in {
    opacity: 0;
    transition: all .9s ease;

    &.show {
        opacity: 1;
    }
}

.qus-title-discalimer {
    p {
        font-weight: 400;
        font-size: get-vw-desktop(23px);
        color: #fff;
        text-align: center;
        margin:get-vw-desktop(5px) 0 ;

        @media only screen and (max-width: 770px) {
            font-size: get-vw-mobile(15px);
            text-align: right;
            margin-bottom:get-vw-mobile(10px);
        }
    }
}

