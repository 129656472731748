// $Afek-Regular: "./../../assets/fonts/afek-regular-aaa";
// $Afek-Medium: "./../../assets/fonts/afek-medium-aaa";
// $Afek-Bold: "./../../assets/fonts/afek-bold-aaa";

$Afek-Regular: "../assets/fonts/afek-regular-aaa";
$Afek-Medium: "../assets/fonts/afek-medium-aaa";
$Afek-Bold: "../assets/fonts/afek-bold-aaa";


@font-face {
    font-family: "Afek";
    font-style: normal;
    font-weight: 400;
    src: local("Afek"),
    url('#{$Afek-Regular}.woff2') format('woff2'),
    // Chrome 26+, Opera 23+, Firefox 39+
    url('#{$Afek-Regular}.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: "Afek";
  font-style: normal;
  font-weight: 500;
  src: local("Afek"),
  url('#{$Afek-Medium}.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
  url('#{$Afek-Medium}.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

@font-face {
  font-family: "Afek";
  font-style: normal;
  font-weight: 700;
  src: local("Afek"),
  url('#{$Afek-Bold}.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
  url('#{$Afek-Bold}.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}